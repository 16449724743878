import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"



const listItemArticle = ({title, imageSharp, imageAlt, linkTo, description, dateModified, datePublished, key}) => {
  return (
    <article className="transition-all duration-300 block w-full mt-12" key={key}>
      <Link className="block overflow-hidden w-full h-full" to={linkTo}>
        <figure className="float-left sm:float-right cat-wh-sm sm:cat-wh ml-0 mr-2 sm:ml-8 sm:mr-0 mb-0 sm:mb-8 rounded overflow-hidden border border-gray-100">
          <GatsbyImage
            className="transform hover:scale-110 transition duration-300"
            image={ getImage( imageSharp ) }
            alt={ imageAlt }
            placeholder="dominantColor"
          />
        </figure>
        <div className="">
          <h2 className="text-base md:text-xl font-bold leading-snug">{title}</h2>
          <p className="w-full mt-0 sm:mt-2 text-xs text-gray-600 text-left leading-normal">
            {description ? <span className="hidden sm:inline">{description} </span> : null}
            {datePublished ? <span className="inline-block italic text-gray-500">―― Posted on {datePublished}</span> : null}
          </p>
        </div>
      </Link>
    </article>
  )
}
export default listItemArticle
