import React from "react"
import { graphql } from 'gatsby'

import Layout from '../components/ui/layout'
import Seo from '../components/ui/seo'
import SvgSns  from '../components/ui/svg-sns'
import Article from '../components/ui/list-item-article'
import Share from '../components/ui/share'
import postListLdjson from '../components/ui/post-list-ldjson'



const category = ({ pageContext: { catLabel }, data, location }) => {
  const pageTitle = `${catLabel}`
  const siteUrl = data.site.siteMetadata.siteUrl
  const url = `${siteUrl}${location.pathname}`
  const postList = data.posts.edges
  let catMeta
  data.site.siteMetadata.categories.forEach( metadata => {
    if ( metadata.label === catLabel ) {
      catMeta = metadata
      return
    }
  })
  const pageDescription = catMeta.description ?
    catMeta.description :
    `連載: ${catLabel} の一覧ページ`
  const catSlug = catMeta.slug
  const twitterid = data.site.siteMetadata.twitterid
  return (
    <Layout>
      <Seo
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        pageUrl={location.pathname}
        breadcrumbList={[
          {position:1, url:siteUrl, name:"Home" },
          {position:2, url:url, name:catLabel },
        ]}
        postList={postListLdjson({postList: postList, catSlug: catSlug,})}
      />
      <SvgSns />
      <main>
        <div className="pt-48">
          <h1 className="text-2xl md:text-3xl text-center font-bold">
            {pageTitle}
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-4 xl:grid-cols-5 gap-4 px-2 md:mx-auto">
            <div className="w-full max-w-screen-md col-span1 md:row-start-1 md:col-span-4 xl:col-span-3 xl:col-start-2 px-2 mt-12 mx-auto">
              { postList.map( ( { node } ) => {
                return (
                  node.frontmatter.eyecatch &&
                  ~node.slug.indexOf( catSlug ) &&
                  <Article
                    title={node.frontmatter.title}
                    imageSharp={node.frontmatter.eyecatch}
                    imageAlt={node.frontmatter.title}
                    linkTo={`/${node.slug}/`}
                    description={node.frontmatter.description}
                    dateModified={node.frontmatter.dateModified}
                    datePublished={node.frontmatter.datePublished}
                    key={`article-${node.frontmatter.slug}`}
                  />
                )
              } ).filter( x => x ) }
            </div>
            <div className="mt-12 col-span-1 md:col-span-4 xl:col-span-1 xl:col-start-1 xl:row-start-1">
              <div className="sticky top-0 pt-10">
                <Share message="ページをシェア" title={catLabel} url={url} twitterid={twitterid} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}
export default category



export const query = graphql`
  query( $orderBy: SortOrderEnum ) {
    posts: allMdx(
      sort: {fields: frontmatter___datePublished, order: [$orderBy]},
      filter: {frontmatter: {
        draft: {eq: false}
      }}
    ) {
      edges {
        node {
          slug
          frontmatter {
            tags
            draft
            eyecatch {
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  width: 256
                )
              }
            }
            dateModified(formatString: "MMMM Do, YYYY")
            datePublished(formatString: "MMMM Do, YYYY")
            title
            description
          }
        }
      }
    }
    site {
      siteMetadata {
        categories {
          label
          slug
          description
        }
        title
        siteUrl
        twitterId
      }
    }
  }
`
